export default {
  clearDelayJump(state) { // 清空定时器
    clearTimeout(state.tempId)
    state.tempId = null;
  },
  setDelayJump(state, callback) { // 定时器
    state.tempId = setTimeout(() => callback&&callback(), 2000);
  },
  setToken(state, token= '') {
    state.token = token;
  },
  setUserInfo(state, data) {
    state.userInfo = data;
  }
}