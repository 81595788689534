const Home = () => import('@page/pages/Home')
const About = () => import('@page/pages/About')
const Business = () => import('@page/pages/Business')
const News = () => import('@page/pages/News')
const NewsInfo = () => import('@page/news/NewsInfo')
const Contact = () => import('@page/pages/Contact')

export default [
  { path: '/', name: 'Home', component: Home},
  { path: '/about', name: 'About', component: About},
  { path: '/business', name: 'Business', component: Business},
  { path: '/news', name: 'News', component: News},
  { path: '/news_info/:id', name: 'NewsInfo', component: NewsInfo},
  { path: '/contact', name: 'Contact', component: Contact}
]