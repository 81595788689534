import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations.js'

Vue.use(Vuex)

const state = {
  config: { // 默认
    baseURL: process.env.NODE_ENV === 'test' ? 'https://auswebapitest.meifanco.cn' : 'https://webapi.meifan.com.au'
  },
  tempId: null, // 定时器
  token: '', // 登录token
  userInfo: null, // 用户信息
}

export default new Vuex.Store({
  state,
  mutations,
  actions: {
  },
  modules: {
  }
})
