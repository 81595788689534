import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import routes from './routes'

Vue.use(VueRouter)  

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : {x : 0, y : 0};
  }
})
router.beforeEach((to, from, next) => {
  store.commit('clearDelayJump');
  next() 
})

export default router
