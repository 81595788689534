import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as utils from '@utils/utils'
import * as filters from '@utils/filters'
import iView from 'iview'
import 'iview/dist/styles/iview.css'
import '@assets/less/public.less'
import moment from 'moment'
import Cookies from 'js-cookie'

Vue.config.productionTip = false;

// 注册全局过滤器
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));

// 注册全局方法
Vue.use({install: Vue => Vue.prototype[`$Cookies`] = Cookies});
Vue.use({install: Vue => Vue.prototype[`$moment`] = moment});
Vue.use({install: (Vue, opt) => {Object.keys(utils).forEach(key => Vue.prototype[`$${key}`] = utils[key])}});

Vue.use(iView);

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
